import styled from "styled-components";

interface InputFieldProps{
    width: string
}
export const Container = styled.div<InputFieldProps>`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: ${(props) => props.width};
`;

export const FieldTitle = styled.p`
    font-size: 20px;
    margin-bottom: 10px;
`
export const Field = styled.input`
    border: none;
    border-bottom: 2px solid black;
    width: 100%;
    outline: none;
    font-size: 18px;
    padding-left: 0px;
`;