import { Search } from "@mui/icons-material";
import { SearchInput, Container } from "./Style";

interface SearchBarInterface {
    setSearchedWord: (searchedWord: string) => void;
}

const SearchBar = ({
    setSearchedWord,
}: SearchBarInterface) => {
    return (
        <Container>
            <SearchInput placeholder="Buscar convênio..." onChange={(searchedWord) => { setSearchedWord(searchedWord.target.value) }} />
            <Search />
        </Container>
    )
}

export default SearchBar;