import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
`;

export const Copy = styled.p`
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin-top: 50px;
`;

export const WPP = styled.a`
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    color: #1f57a1;
`;

export const Logo = styled.img`
    height: 150px;
    margin-bottom: 25px;
`