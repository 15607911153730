import React, { ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, config } from 'react-spring';
import { Section } from './Style';
import { duration } from '@mui/material';

interface AnimatedSectionProps {
    children: ReactNode;
    id: string;
    direction: 'left' | 'right';
}

const AnimatedSection: React.FC<AnimatedSectionProps> = ({ children, id, direction }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const animation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateX(0)' : direction === 'left' ? 'translateX(-100px)' : 'translateX(100px)',
        config: {
            duration: 500,
        }
    });

    return (
        <Section id={id} ref={ref} style={animation}>
            {children}
        </Section>
    );
};

export default AnimatedSection;
