import styled, { keyframes } from "styled-components";

const fadeInOut = keyframes`
    0% {
        opacity: 0;
    }
    8% {
        opacity: 1;
    }
    92% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
`;

export const CardsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 80%;
    gap: 30px;
    width: fit-content;

    @media(max-width: 750px){
        max-width: 100%;
        gap: 15px
    }
`;


export const Card = styled.div`
    opacity: 0;
    animation: ${fadeInOut} 10s linear infinite;
`;

export const PanelTitle = styled.p`
    width: 90%;
    text-align: left;
    font-family: 'Times New Roman', Times, serif;
    font-size: 54px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0px;
    margin-bottom: 20px;

    @media(max-width: 750px){
        text-align: center;
        line-height: 44px;
        font-size: 48px;
    }
`;

export const PanelText = styled.p`
    width: 100%;
    text-align: justify;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    margin-top: 5px;
    margin-bottom: 20px;
    max-width: 90%;

    @media(max-width: 750px){
    }
`;

export const ShowMoreButton = styled.div`
    align-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    background-color: white;
    border-radius: 15px;
    margin-bottom: 30px;
    margin-right: 5.5%;
    filter: drop-shadow(1px 2px 3px black);
    width: 110px;
    height: 25px;
    padding: 10px;
    cursor: pointer;
    &:hover{
        transform: scale(1.05);
        transition: 0.1s ease-in;
    }

    @media(max-width: 750px){
        align-self: center;
        margin-bottom: 0px;
        margin-top: 30px;
        width: 78%;
        margin-right: 0%;
        justify-content: center;
        gap: 5px;
    }
`;

export const ButtonText = styled.p`
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0px;
`;

export const CardButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media(max-width: 750px){
        flex-direction: column-reverse;
    }
`;

export const ModalBody = styled.div`
    display: flex;
    width:100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const ModalCardsWrapper = styled.div`

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 75vw;
    gap: 30px;
    width: fit-content;
    overflow-y: auto;
    max-height: 60vh;   
    padding: 3px;
    justify-content: center;

    @media(max-width: 750px){
        max-width: 100%;
        gap: 15px;
        max-height: 78vh;
    }
`;