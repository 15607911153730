import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Backdrop, Wrapper, StyledModal, Header, Title, Body } from './Style';
import { CancelOutlined } from '@mui/icons-material';

interface BlankModalInterface {
    isShown: boolean;
    hide: () => void;
    headerText: string;
    bodyContent: JSX.Element;
};

const BlankModal = ({
    isShown,
    hide,
    headerText,
    bodyContent
}: BlankModalInterface) => {

    const modal = (
        <React.Fragment>
            <Backdrop onClick={hide} />
            <Wrapper>
                <StyledModal>
                    <Header>
                        <Title>{headerText}</Title>
                        <CancelOutlined onClick={hide} style={{ cursor: 'pointer' }}></CancelOutlined>
                    </Header>
                    <Body>
                        {bodyContent}
                    </Body>
                </StyledModal>
            </Wrapper>
        </React.Fragment>
    );

    return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

export default BlankModal;