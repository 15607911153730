import { useState } from 'react';
import { WhatsApp } from "@mui/icons-material";
import { Container, Message } from "./style";

const WppButton = () => {
    const [hover, setHover] = useState(false);

    return (
        <Container
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            hover={hover}
            onClick={() => { window.open('https://api.whatsapp.com/send?phone=5561996229597&text=Seja%2C%20bem%20vindo%20a%20Clinica%20de%20Psicologia%20do%20Dr%C2%BA%20Eduardo%20Coelho.%20Para%20agilizar%20o%20atendimento%2C%20pode%20informar%20o%20seu%20nome%20e%20como%20podemos%20te%20ajudar%20neste%20momento%3F') }}
        >
            <WhatsApp style={{ height: '45px', width: '45px', color: 'green' }} />
            {hover && <Message hover={hover}>Agende sua sessão</Message>}
        </Container>
    )
}

export default WppButton;
