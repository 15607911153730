import styled from "styled-components";

interface ContainerProps {
    hover: boolean;
}

export const Container = styled.div<ContainerProps>`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: ${props => (props.hover ? 'space-between' : 'end')};
    bottom: 37.5px;
    right: 37.5px;
    background-color: white;
    border-radius: 37.5px;
    height: 75px;
    width: ${props => (props.hover ? '255px' : '75px')};
    filter: drop-shadow(1px 2px 3px black);
    z-index: 999;
    flex-direction: row-reverse;
    transition: width 0.3s ease, justify-content 0.3s ease;
    overflow: hidden;
    box-sizing: border-box;
    padding: 15px;
    cursor: pointer;

    @media(max-width: 928px){
        bottom: 18.75px;
        right: 17.75px;
    }
`;

interface MessageProps {
    hover?: boolean;
}

export const Message = styled.div<MessageProps>`
    margin-right: 15px;
    margin-left: 15px;
    font-size: 18px;
    white-space: nowrap;
    transition: opacity 0.3s ease;
    opacity: ${props => (props.hover ? 1 : 0)};
`;
