import { Link } from 'react-scroll';
import styled, { keyframes } from 'styled-components';

interface NavbarProps {
    visible: boolean;
}
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
`;

export const NavbarContainer = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 70px;
  width: 100%;
  position: fixed;
  filter: drop-shadow(1px 2px 3px black);
  z-index: 1000;
  padding-right: 10px;
`;

export const NavbarList = styled.ul`
  display: flex;
  list-style-type: none;
  align-items: center;

  @media (max-width: 1100px){
    display: none;
  }
`;

export const NavbarItem = styled.li`
  margin-right: 50px;
  align-items: center;

  @media (max-width: 750px){
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const NavbarLink = styled(Link)`
  text-decoration: none;
  color: black;
  cursor: pointer;
  transition: all 0.3s ease;
  align-items: center;

  &:hover {
    color: lightgray;
  }

  &.active {
    font-size: 24px;
    color: #1e56a1;
    font-weight: bolder;
  }
`;

export const AnimatedNavbarContainer = styled(NavbarContainer) <NavbarProps>`
  animation: ${({ visible }: { visible: boolean }) => (visible ? fadeIn : fadeOut)} 0.7s ease-in-out forwards;
`;

export const Logo = styled.img`
    height: 60px;
    position: absolute;
    left: 30px;
`
export const Expand = styled.div`
    display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  position: fixed;
  box-shadow: 0 4px 2px -2px #333333;
  z-index: 1000;
  padding-right: 10px;
  flex-direction: column;
  margin-top: 68px;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResponsiveList = styled.li`
  display: flex;
  list-style-type: none;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
`