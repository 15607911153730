import { Container, ScheduleContainer, TextButtonContainer, AppointmentButtonText } from "./Style";
import Form from "../../components/AppointmentForm/Index";
import AppointmentButton from "../../components/AppointmentButton/Index";
import { PanelText, PanelTitle } from "../Issurances/Style";

const ScheduleSession = () => {
    return (
        <Container>
            <PanelTitle>
                Agende Sua Sessão
            </PanelTitle>
            <PanelText>
                Agora que você pode me conhecer um pouco, me deixe te conhecer melhor também. Agende uma sessão abaixo de forma prática para que possamos conversar e eu possa te ajudar nessa nova etapa.
            </PanelText>
            <ScheduleContainer>
                <Form></Form>
                <TextButtonContainer>
                    <AppointmentButtonText>
                        Você também pode agendar sua consulta diretamente pelo nosso Whatsapp clicando no botão abaixo. Nós acertaremos todos os detalhes e logo teremos nossa sessão.
                    </AppointmentButtonText>
                    <AppointmentButton></AppointmentButton>
                </TextButtonContainer>

            </ScheduleContainer>
        </Container>

    )
}

export default ScheduleSession;