import Carrousel from "../../components/Carrousel/Index";
import DataDisplayCard from "../../components/DataDisplayCard/Index";
import { CardsWrapper, Container } from "./Style";

const ReviewsPanel = () => {
    return (
        <Container>
            <Carrousel />
        </Container>
    )
}

export default ReviewsPanel;