import { CheckCircle, CheckCircleOutline } from "@mui/icons-material";
import AppointmentButton from "../../components/AppointmentButton/Index";
import { Container, ContentContainer, PageText, TextButtonContainer, Title, TopicsContainer, TopicsTitle } from "./Style"
import TopicCard from "../../components/TopicCard/Index";

const AboutPsychology = () => {

    const topics: string[] = ["Você sente medo extremo, ou angústia diante de situações simples?",
        "Você se preocupa excessivamente?",
        "Você se sente triste e solitário por longos períodos de tempo?",
        "Você sempre espera o pior, estando constantemente tenso?",
        "Você não consegue se concentrar no trabalho e focar em atividades diárias?",
        "Você apresenta preocupação exagerada em comparação com a realidade?",
        "Você tem problemas para dormir?",
        "Você está com problemas de relacionamentos?",
        "Você sente irritabilidade, por longos períodos?"];

    const pageText: string = "\"Não há um momento específico em que devemos iniciar a psicoterapia. Optar iniciar a terapia também é uma prova de amor próprio, pois decidimos lutar pelo nosso bem-estar, e autocuidado.\"";

    const topicsCards = () => {
        const cards = topics.map(index => {
            return <TopicCard topic={index} />
        });
        return cards;
    }
    return (
        <Container>
            <Title>Por que buscar um psicólogo?</Title>
            <ContentContainer>
                <TopicsContainer>
                    <TopicsTitle>
                        Você se identifica com essas situações?
                    </TopicsTitle>
                    {topicsCards()}
                </TopicsContainer>

                <TextButtonContainer>
                    <PageText>
                        {pageText}
                    </PageText>
                    <AppointmentButton />
                </TextButtonContainer>

            </ContentContainer>
        </Container>
    )
}

export default AboutPsychology;