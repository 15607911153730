import { useState } from "react";
import { Container, ContentWrapper, ImageContainer, MiniMapContainer, Picture, MiniMapText } from "./Style";
import MiniMap from "../../components/MIniMap/Index"
import { LocationOn } from "@mui/icons-material";
import { PanelText, PanelTitle } from "../Issurances/Style";

const Appointments = () => {

    const [front, setFront] = useState<boolean>(true);

    const text: string = "As sessões podem acontecer de segunda à sábado tanto no modelo presencial, quanto on-line. Com duração de 30 minutos à 1 hora, onde conversaremos sobre tudo o que desejar. Os encontros presenciais ocorrem no meu consultório situado na torre A do Taguatinga Shopping, onde você terá toda a privacidade e conforto."

    return (
        <Container>
            <PanelTitle>Sessões Presenciais e On-line</PanelTitle>
            <PanelText>
                {text}
            </PanelText>
            <ContentWrapper>
                <ImageContainer onClick={() => { setFront(!front) }}>
                    <Picture src={require("../../assets/Office/Room1.jpg")} front={front}></Picture>
                    <Picture src={require("../../assets/Office/TaguatingaShoppingGD.jpg")} front={!front}></Picture>
                </ImageContainer>
                <MiniMapContainer>
                    <MiniMap></MiniMap>
                    <MiniMapText>Taguatinga Shopping, QS 01 Lote 40 - Taguatinga, Brasília - DF, 71950-904. Torre A, Sala 402 <LocationOn /></MiniMapText>
                </MiniMapContainer>
            </ContentWrapper>
        </Container>
    )
}

export default Appointments;