import React, { useState } from 'react';
import { Container, Field, FieldContainer, FieldLabel, FieldTitle } from './Style'; // Importe os estilos conforme necessário

interface RadioInput {
    title: string;
    options: string[];
    defaultOption: number;
    width: string;
    setValue: (value: string) => void;
    value: string;
}

const RadioInput = ({
    title,
    options,
    defaultOption,
    width,
    setValue,
    value
}: RadioInput) => {

    const [selectedOption, setSelectedOption] = useState<string>(options[defaultOption - 1]);

    const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        setSelectedOption(value);
        setValue(value);
    };

    const renderOptions = () => {
        return options.map((option, index) => {
            const id = `${title}${index}`;
            return (
                <React.Fragment key={id}>
                    <FieldContainer>
                        <Field
                            type="radio"
                            id={id}
                            name={title}
                            value={option}
                            checked={option === selectedOption}
                            onChange={handleOptionChange}
                        />
                        <FieldLabel htmlFor={id}>{option}</FieldLabel>
                    </FieldContainer>
                </React.Fragment>
            );
        });
    };

    return (
        <Container width={width}>
            <FieldTitle>
                {title}
            </FieldTitle>
            {renderOptions()}
        </Container>
    );
};

export default RadioInput;
