import styled, { keyframes, css } from "styled-components";

const img = require("../../assets/Extra/testimg.jpeg");

interface textVisibility {
    visible: boolean;
};

const scrollReveal = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 800px;
    opacity: 1;
  }
`;

export const Container = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
    padding-left: 0px;
`;

export const Title = styled.h1`
    width: 100%;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 50px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 0px;
    margin-bottom: 50px;

    @media (max-width: 928px){
        line-height: 44px;
        font-size: 48px;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 85%;
    border-radius: 15px;
    background-color: white;
    filter: drop-shadow(1px 2px 3px black);
    padding-left: 0px;
    padding-right: 0px;

    @media (max-width: 1248px){
        width: 95%;
    };

    @media (max-width: 928px){
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-flow: column-reverse;
    };
`;

export const Picture = styled.div`
    background-image: url(${img});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    align-self: flex-start;
    justify-self: flex-start;
    width: 45%;
    height: 100%;
    min-height: 350px;
    border-radius: 15px 0 0 15px;
    left: 0px;
    place-content: flex-end;
    box-sizing: border-box;
    padding-bottom: 25px;
    
    @media(max-width: 928px){
        border-radius: 0 0 15px 15px;
        height: 30%;
        width: 100%;
        align-self: end;
        margin-top: 25px;
        height: auto;
    }
`;

export const PageText = styled.p<textVisibility>`
    display: ${props => props.visible ? 'block' : 'none'};
    width: 90%;
    min-width: 350px;
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 10px;
    font-family: 'Times New Roman', Times, serif;
    text-align: justify;

    ${({ visible }) =>
        visible &&
        css`
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      animation: ${scrollReveal} 3s forwards;
    `}

    @media(max-width: 928px){
        min-width: 100px;
        width: 95%;
    }

`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 100%;
    width: 55%;
    overflow: hidden;
    padding: 25px;
    padding-bottom:15px;
    box-sizing: border-box; 
    gap: 15px;

    @media(max-width: 928px){
        margin-left: 0;
        width: 90%;
        padding: 5px;
        padding-top: 20px;
    }
`;



export const SubtitlesContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 90%;
    align-items: center;
    height: 34px;
    @media(max-width: 928px){
        margin-left: 0;
        width: 100%;
    }
`;

export const Subtitles = styled.h1`
    text-align: start;
    font-family: 'Times New Roman', Times, serif;
    font-size: 34px;
    font-weight: 500;
    letter-spacing: 0px;
    
    @media(max-width: 928px){
        font-size: 28px;
    }
`;

export const Divider = styled.div`
    background-color: black;
    height: 1px;
    width: 90%;
    border-radius: 1px;
    opacity: 50%;

    @media(max-width: 928px){
        width: 100%;
    }
`;