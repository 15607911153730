import { Container, FormButton, FormTitle, MultiField, TitleContainer } from "./Style"
import InputField from "../InputField/Index";
import { useState } from "react";
import RadioInput from "../RadioInput/Index";
import { HelpOutline } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import emailjs from "@emailjs/browser";

const Form = () => {

    const [nome, setNome] = useState<string>('');
    const [telefone, setTelefone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [dataNascimento, setDataNascimento] = useState<string>('');
    const [dataSessao, setDataSessao] = useState<string>('');
    const [sexo, setSexo] = useState<string>('Não Informar');
    const [modalidade, setModalidade] = useState<string>('Qualquer');

    const emailService = process.env.REACT_APP_EMAIL_SERVICE;
    const emailTemplate = process.env.REACT_APP_EMAIL_TEMPLATE;
    const emailPublic = process.env.REACT_APP_EMAIL_PUBLIC_KEY;

    const message: string = `Nome: ${nome}\nTelefone: ${telefone}\nEmail: ${email}\nData de Nascimento: ${dataNascimento}\nSexo: ${sexo}\nModalidade: ${modalidade}\nData de preferêcia da sessão: ${dataSessao}`;

    const templateParams = {
        form_name: nome,
        message: message
    }

    const clearValues = () => {
        setNome('');
        setTelefone('');
        setEmail('');
        setDataNascimento('');
        setDataSessao('');
    };

    const submit = () => {

        if (nome === '' || telefone.length < 9 || email === '' || dataNascimento === '' || dataSessao === '') {
            alert("Preencha todos os campos antes de enviar o formulário.");
            return;
        }

        emailjs.send(emailService, emailTemplate, templateParams, emailPublic).then((res) => {
            alert("Solicitação enviada com sucesso! Em breve entraremos em contato para agendarmos nossa sessão.");
            clearValues();
        }, (err) => {
            alert("Erro ao processar sua solicitação, tente novmente. Se o problema persistir, agende diretamente pelo nosso WhatsApp.");
            console.log(err);
        }
        );
    }

    return (
        <Container>
            <TitleContainer>
                <FormTitle>Agendamento rápido</FormTitle>
                <Tooltip placement="right" style={{ 'backgroundColor': 'white' }} title="Preencha o formulário e clique em enviar. Nós enviaremos uma mensagem confirmando sua sessão o mais rápido o possível.">
                    <HelpOutline />
                </Tooltip>
            </TitleContainer>
            <InputField title="Nome" setValue={setNome} placeholder="nome" width="85%" value={nome}></InputField>
            <InputField title="Telefone" setValue={setTelefone} placeholder="(xx)xxxxx-xxxx" width="85%" type="tel" value={telefone}></InputField>
            <InputField title="Email" setValue={setEmail} placeholder="exemplo@email.com" width="85%" type="email" value={email}></InputField>
            <MultiField>
                <InputField title="Data de Nascimento" setValue={setDataNascimento} placeholder="dd/mm/aaaa" type="date" width="46%" value={dataNascimento}></InputField>
                <InputField title="Data da Sessão" setValue={setDataSessao} placeholder="dd/mm/aaaa" type="date" width="46%" value={dataSessao}></InputField>
            </MultiField>
            <MultiField>
                <RadioInput title="Sexo" options={["Mascuilo", "Feminino", "Não informar"]} defaultOption={3} width="46%" setValue={setSexo} value={sexo} />
                <RadioInput title="Modalidade" options={["Presencial", "On-line", "Qualquer"]} defaultOption={3} width="46%" setValue={setModalidade} value={modalidade} />
            </MultiField>
            <MultiField>
                <FormButton onClick={submit} primary={true}>
                    Agendar
                </FormButton>
                <FormButton onClick={clearValues} primary={false}>
                    Limpar
                </FormButton>
            </MultiField>
        </Container>
    )
};

export default Form;