import { text } from "stream/consumers";

export interface Review {
    author: string;
    text: string;
}

export const Reviews: Review[] = [
    {author:"C.S", text: "Minha experiência em terapia com o Dr. Eduardo, foi com certeza, a melhor coisa que fiz em minha vida! Aprendi a me conhecer e impor limites, coisa que não fazia antes. Essencial e Dr. Eduardo sabe como ativar nossos gatilhos. Recomendo, hoje sou outra mulher!"},
    {author:"L.B", text: "Busquei a psicoterapia para me resolver com problemas pessoais e emocionais, fico feliz em ter feito com o Dr. Eduardo, foi alguém incrível em minha vida e só tenho a agradecer por ter tido esse excelente profissional como psicólogo!"},
    {author:"C.A", text: "Psicólogo bem atencioso e com ótimas abordagens durante a terapia e que também nos dá bastante liberdade acerca do assunto nessa, recomendo muito."},
    {author:"J.B", text: "Dr. Eduardo é um profissional excelente, atencioso, que te escuta sem julgamentos e com grandes resoluções para a vida. Cheguei em uma fase muito difícil de acontecimentos na minha vida e durante 1 ano de terapia, minha melhora e meu desenvolvimento melhoraram exponencialmente. Recomendo este profissional a todos sem exceção!"},
    {author:"A.B", text: "Agradeço tudo o que foi me mostrado durante a terapia, pois é um processo de se rasgar e se deixar curar intenso, mas extremamente necessário, e acredito que foi através do auxílio do Dr. Eduardo e da terapia que me mostrou o leque de oportunidades que a vida tem!"},
    {author:"R.A", text: "Bom comecei a terapia no ano de 2019 em Brasília, infelizmente tive que sair por mudança de estado! Assim, que o Dr. Eduardo começou a atender on-line, voltei a terapia diretamente do estado do Espírito Santo! Fico feliz por poder contar com esse profissional, hoje sou uma pessoa mais equilibrada, organizada e com uma melhor alto estima! Faço terapia online e indico para todos que querem ser acolhidos e precisem de auxílio. Relutei tempos a ir, mas sem dúvidas foi uma das decisões mais importantes da minha vida! Gratidão"},
    {author:"L.N", text: "Minha experiência em seu consultório foi muito importante para o desenvolvimento de uma auto-análise positiva, tão necessária a qualquer pessoa que esteja enfrentando conflitos em casa e no trabalho. O profissionalismo com o qual me atendeu permitiu o desenvolvimento de uma confiança no processo terapêutico. Ao longo dos encontros fomos enfrentando a natureza das dificuldades apresentadas e observei que tem surtido efeito prático no meu dia a dia. Agradeço pelo seu trabalho e dedicação."},
    {author:"E.N", text: "Decidi iniciar o acompanhamento psicológico em busca de autoconhecimento e um maior equilíbrio emocional. Foi uma experiencia transformadora, me proporcionando diversas reflexões e desenvolvimento pessoal. A abordagem acolhedora do Dr. Eduardo proporcionou um ambiente seguro. Agradeço pelas orientações e contribuição fundamental para o meu bem-estar emocional."},
    {author:"J.C", text: "Minha experiência com a terapia sempre foi muito boa. Autoconhecimento é fundamental para nosso crescimento e compreensão de nossos sentimentos e emoções. Sozinho não teria chegado até aqui. Tenho muita gratidão e reconhecimento pelo trabalho do Dr. Eduardo neste processo de autodescoberta."},
    {author:"C.R", text: "Fui paciente do Dr. Eduardo e com tantos problemas que tinha, as terapias que tive com ele foram maravilhosas, e me ajudou a solucioná-los. Ele é um profissional excelente me deu soluções para os meus problemas que não são poucos. Agradeço de coração o que fez por mim."},
    {author:"D.P", text: "Foi uma experiência maravilhosa e de autoconhecimento. Busquei a terapia em um momento que precisava muito de ajuda de um verdadeiro profissional. Agradeço o Dr. Eduardo por toda atenção e profissionalismo. Fez toda diferença no meu processo."},
    {author:"P.A", text: "Creio que toda pessoa deveria fazer, em algum momento da vida, psicoterapia. Ajuda muito a colocar as ideias no lugar certo. Encontrei com o Dr° Eduardo aquilo que buscava, ou seja, através de uma visão técnica e humana, uma troca de experiências, de conceitos, de formas de ver a vida. Ainda ganhei um amigo."},
    {author:"E.S", text: "Gostaria de exaltar o maravilhoso profissional  é o Dr Eduardo, cheguei a ele através de indicação do meu plano de saúde na época, e durante o período em que fiz o meu tratamento com ele foi muito bom para mim. Ele é um excelente profissional e excelente pessoa."},
    {author:"E.S", text: "Eu não entendia como me relacionar com as pessoas ao meu redor, mas sabia que precisava de ajuda nesse quesito. Sempre ouvi relatos de que era difícil encontrar um terapeuta e que era necessário afinidade para que o processo desse certo. Eduardo, fico feliz por ter tido afinidade com você desde a primeira consulta, você me ajudou a entender que preciso aprender a lidar com as minhas questões. Penso sempre em nossas conversas e em como era bom, sinto muita gratidão por tudo que você me possibilitou resolver e entender."},

]