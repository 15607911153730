import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ButtonText, Container } from "./Style";

interface AppointmentButtonInterface {
    marginTop?: string;
    onClick?: VoidFunction;
}

const AppointmentButton = ({
    marginTop,
    onClick
}: AppointmentButtonInterface) => {
    return(
        <Container onClick={() => {window.open('https://api.whatsapp.com/send?phone=5561996229597&text=Seja%2C%20bem%20vindo%20a%20Clinica%20de%20Psicologia%20do%20Dr%C2%BA%20Eduardo%20Coelho.%20Para%20agilizar%20o%20atendimento%2C%20pode%20informar%20o%20seu%20nome%20e%20como%20podemos%20te%20ajudar%20neste%20momento%3F') }} marginTop={marginTop}>
            <ButtonText >Agende sua sessão</ButtonText>
            <AccessTimeIcon></AccessTimeIcon>
        </Container>
    )
}

export default AppointmentButton;