import React from "react";
import { Container, Splitter, Section} from "./Style";
import Intro from "../../panels/Intro/Index";
import Appointments from "../../panels/Appointments/Index";
import AboutMe from "../../panels/AboutMe/Index";
import Footer from "../../panels/Footer/Index";
import Issurances from "../../panels/Issurances/Index";
import Reviews from "../../panels/Reviews/Index";
import Navbar from "../../components/Navbar/Index";
import AboutPsychology from "../../panels/AboutPsychology/Index";
import ScheduleSession from "../../panels/ScheduleSession/Index";
import WppButton from "../../components/WppButton";
import AnimatedSection from "./AnimatedSection"; // Importar o novo componente

const HomePage: React.FC = () => {
    return (
        <>
            <Navbar />
            <Container>
                <AnimatedSection id="intro" direction="left">
                    <Intro />
                </AnimatedSection>
                <Splitter src={require('../../assets/Extra/SectionSplitter.png')} />
                <AnimatedSection id="psychology" direction="right">
                    <AboutPsychology />
                </AnimatedSection>
                <Splitter src={require('../../assets/Extra/SectionSplitter.png')} />
                <AnimatedSection id="about" direction="left">
                    <AboutMe />
                </AnimatedSection>
                <Splitter src={require('../../assets/Extra/SectionSplitter.png')} />
                <AnimatedSection id="appointments" direction="right">
                    <Appointments />
                </AnimatedSection>
                <Splitter src={require('../../assets/Extra/SectionSplitter.png')} />
                <AnimatedSection id="issurances" direction="left">
                    <Issurances />
                </AnimatedSection>
                <Splitter src={require('../../assets/Extra/SectionSplitter.png')} />
                <AnimatedSection id="reviews" direction="right">
                    <Reviews />
                </AnimatedSection>
                <Splitter src={require('../../assets/Extra/SectionSplitter.png')} />
                <AnimatedSection id="schedule" direction="left">
                    <ScheduleSession />
                </AnimatedSection>
                <Splitter src={require('../../assets/Extra/SectionSplitter.png')} />
                <Section id="footer">
                    <Footer />
                </Section>
            </Container>
            <WppButton />
        </>
    );
}

export default HomePage;
