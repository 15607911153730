import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 25px;
`;

export const Icon = styled.div`
    display: flex;
    background-color: white;
    filter: drop-shadow(1px 2px 3px black);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover{
        transform: scale(1.05);
        transition: 0.1s ease-in;
    }
`;
