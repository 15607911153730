import './App.css';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import Router from './Routes';
import Navbar from './components/Navbar/Index';

function App() {
  return (
    <>
      <RouterProvider router={Router} />
    </>
  );
}

export default App;
