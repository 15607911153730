import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-direction: row;
    margin-top: 50px;

    @media(max-width: 750px){
        flex-direction: column-reverse;
        gap: 30px;
    }
`;

export const ContentBox = styled.div`
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    height: 90vh;
    padding-left: 5%;
    padding-right: 4%;
    flex-direction: column;
    @media(max-width: 750px){
        height: auto;
        width: 95%;
        gap: 10px;
    }
`

export const Picture = styled.img`
    width: 500px;
    filter: drop-shadow(1px 2px 3px black);
    @media(max-width: 750px){
        width: 100%;
    }
`

export const PageText = styled.p`
    width: 100%;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    @media(max-width: 750px){
        text-align: center;
    }
`

export const PageTitle = styled.h1`
    width: 100%;
    text-align: left;
    font-family: 'Times New Roman', Times, serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0px;
    margin-bottom: 30px;
    @media(max-width: 750px){
        text-align: center;
    }
`
export const ImageTitle = styled.img`
    width: 120%;
    margin-bottom: 10px;


    @media(max-width: 750px){
        width: 100%;
    }
`