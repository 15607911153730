import { Topic, TopicText } from "./Style";
import { CheckCircle, CheckCircleOutline } from "@mui/icons-material";
import { useState } from "react";

interface TopicCardInterface {
    topic: string;
}

const TopicCard = ({ topic }: TopicCardInterface) => {
    const [check, setCheck] = useState<Boolean>(false);

    const toggleCheck = () => {
        setCheck(!check);
    }

    return (
        <Topic onClick={toggleCheck}>
            <TopicText>
                {topic}
            </TopicText>
            {check ? <CheckCircle /> : <CheckCircleOutline />}
        </Topic>
    );
}

export default TopicCard;