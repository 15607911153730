import styled from "styled-components";

export const Wrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1003;
    width: inherit;
    outline: 0;
`;

export const Backdrop = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1002;
`;

export const StyledModal = styled.div`
    display: flex;
    z-index: 100;
    background: aliceblue;
    position: relative;
    margin: auto;
    border-radius: 15px;
    flex-direction: column;
    padding: 16px;
    width: 65vw;
    height: 80vh;

    @media (max-width: 750px){
        width: 90vw;
        height: 92vh;
    }
`;

export const Header = styled.div`
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
`;

export const Title = styled.div`
    width: 100%;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0px;
    margin-bottom: 20px;
`;

export const Body = styled.div`
    display: flex;
    padding: 8px;
    justify-content: center;
    width: 100%;
`;