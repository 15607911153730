import { Container } from "./Style";

interface DataDisplayCardInterface {
    content: JSX.Element;
}

const DataDisplayCard = ({ content }: DataDisplayCardInterface) => {
    return (
        <Container>
            {content}
        </Container>
    )
}

export default DataDisplayCard;