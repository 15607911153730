import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 305px;
    height: 455px;
    overflow: hidden;
    padding: 25px;
    border-radius: 15px;
    background-color: white;
    filter: drop-shadow(1px 2px 3px black);
    box-sizing: border-box;
`;