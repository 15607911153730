import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ScheduleContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 85%;
    align-items: center;
    justify-content: space-around;
    gap: 50px;
    margin-bottom: 50px;
    margin-top: 25px;
    @media(max-width: 928px){
        width: 95%;
        justify-content: center;
        gap: 25px;
        margin-bottom: 0px;
        margin-top: 10px;
    }
`;

export const TextButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33%;
    min-width: 400px;
    height: 100;
    gap: 50px;
    @media(max-width: 928px){
        gap: 15px;
        min-width: 300px;
        width: 95%;
    }
`;

export const AppointmentButtonText = styled.p`
    font-size: 21px;
    text-align: center;
`;