import styled from "styled-components";

interface PictureProps {
    front: boolean;
}

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const PanelTitle = styled.p`
    width: 90%;
    text-align: left;
    font-family: 'Times New Roman', Times, serif;
    font-size: 54px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0px;
    margin-bottom: 40px;

    @media(max-width: 750px){
        text-align: center;
        line-height: 44px;
        font-size: 48px;
        margin-bottom: 20px;
    }
`;

export const PanelText = styled.p`
    width: 85%;
    text-align: justify;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    margin-top: 5px;
    margin-bottom: 20px;
    max-width: 100%;
    margin-left: 25px;
    margin-right: 15px;

    @media(max-width: 750px){
        width: 90%;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    max-width: 95%;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
`;

export const Picture = styled.img<PictureProps>`
    max-width: 375px;
    min-height: 270px;
    border-style: solid;
    border-color: white;
    filter: drop-shadow(1px 2px 3px black);
    border-width: 10px;
    position: absolute;
    top: ${props => props.front ? '65px' : '100px'};
    right: ${props => props.front ? '110px' : '130px'};
    transform: rotate(${props => props.front ? 5 : -15}deg);
    z-index: ${props => props.front ? 101 : 100};

    @media(max-width: 500px){
        max-width: 300px;
        max-height: 220px;
        min-height: 200px;
        left: 25%;
    }
    
`

export const ImageContainer = styled.div`
    position: relative;
    width: fit-content;
    min-width: 600px;
    min-height: 500px;
    height: fit-content;
    overflow: visible;
    
    @media(min-width: 750px){
    &:hover{
        cursor: pointer;
        transform: scale(1.1) rotate(-5deg);
        transition: transform 0.2s ease;
    }
}

    @media(max-width: 750px){
        margin-top: -30px;
        width: 95vw;
        min-width: none;
        min-height: none;
    }
`;

export const MiniMapContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 15px;
    background-color: white;
    margin: 3px;
    filter: drop-shadow(1px 2px 3px black);
    width: 450px;
    height: 450px;
    @media(max-width: 500px){
        margin-top: -115px;
    }
`

export const MiniMapText = styled.p`
    font-size: 15px;
`