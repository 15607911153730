export interface Issurance {
    name: string;
    logo: string;
    sinoms?: string[];
}

export const Issurances: Issurance[] = [
    {name: "AGF Seguros", logo: require("../assets/Issurances/AGF_Seguros_Brasil.png"), sinoms: ["Allianz"]},
    {name: "Bacen", logo: require("../assets/Issurances/Bacen.png"), sinoms: ["Banco Central", "Banco Central do Brasil"]},
    {name: "Afeb Brasal", logo: require("../assets/Issurances/Brasal.png")},
    {name: "Caesan", logo: require("../assets/Issurances/Caesan.png")},
    {name: "Saúde Caixa", logo: require("../assets/Issurances/Caixa.png")},
    {name: "Care Plus", logo: require("../assets/Issurances/Care-plus.png")},
    {name: "Casembrapa", logo: require("../assets/Issurances/Casembrapa.png"), sinoms: ["Embrapa"]},
    {name: "Cassi", logo: require("../assets/Issurances/Cassi.png"), sinoms: ["Banco do Brasil"]},
    {name: "CNTI", logo: require("../assets/Issurances/CNTI.png")},
    {name: "Codevasf", logo: require("../assets/Issurances/Codevasf.jpeg")},
    {name: "CONAB", logo: require("../assets/Issurances/Conab.png")},
    {name: "E-vida Eletronorte", logo: require("../assets/Issurances/Evida.png")},
    {name: "Fapes BNDES", logo: require("../assets/Issurances/Fapes.png")},
    {name: "Fascal", logo: require("../assets/Issurances/Fascal.png")},
    {name: "Fusex", logo: require("../assets/Issurances/Fusex.png"), sinoms: ["Exército"]},
    {name: "Gama Saúde", logo: require("../assets/Issurances/Gama.png")},
    {name: "INAS", logo: require("../assets/Issurances/Inas.png"), sinoms: ["GDF Saúde"]},
    {name: "Life Empresarial", logo: require("../assets/Issurances/Life_Empresarial.png")},
    {name: "OMNIT", logo: require("../assets/Issurances/OMNIT.png")},
    {name: "PF Saúde", logo: require("../assets/Issurances/PF.png"), sinoms: ["Polícia Federal"]},
    {name: "Plan Assiste", logo: require("../assets/Issurances/Plan_Assiste.png"), sinoms: ["MPU", "MPF", "MPM", "MPT"]},
    {name: "PLAS JMU", logo: require("../assets/Issurances/PLAS_JMU.png"), sinoms: ["Justiça Militar da União"]},
    {name: "Postal Saúde", logo: require("../assets/Issurances/Postal.png"), sinoms: ["Correios"]},
    {name: "Pró-Saúde", logo: require("../assets/Issurances/Pro_saude.png"), sinoms: ["Câmara dos Deputados"]},
    {name: "Pró-Social", logo: require("../assets/Issurances/Pro_Social.png")},
    {name: "Proasa", logo: require("../assets/Issurances/Proasa.png")},
    {name: "Pró-ser", logo: require("../assets/Issurances/Proser.png"), sinoms:["STJ"]},
    {name: "Real Grandeza - Furnas", logo: require("../assets/Issurances/Real-grandeza.png")},
    {name: "Serpro", logo: require("../assets/Issurances/Serpro.png")},
    {name: "SIS", logo: require("../assets/Issurances/SIS.png"), sinoms: ["Senado federal"]},
    {name: "STFMed", logo: require("../assets/Issurances/STFMed.png")},
    {name: "TJDTF", logo: require("../assets/Issurances/TJDTF.png")},
    {name: "TRE Saúde", logo: require("../assets/Issurances/TRE.png")},
    {name: "TRT 5 Saúde", logo: require("../assets/Issurances/TRT.png")},
    {name: "TST Saúde", logo: require("../assets/Issurances/TST.png")},
    {name: "Unafisco Saúde", logo: require("../assets/Issurances/Unafisco.png")},
    {name: "Univida", logo: require("../assets/Issurances/univida.png")},
]
