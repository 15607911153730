import React, { useState, useEffect } from 'react';
import { AnimatedNavbarContainer, Expand, Logo, NavbarContainer, NavbarItem, NavbarLink, NavbarList, ResponsiveList, ResponsiveWrapper } from './Style';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar: React.FC = () => {
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(true);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const [mobileMenu, setMobileMenu] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const isVisible = prevScrollPos > currentScrollPos || currentScrollPos < 100;

            setPrevScrollPos(currentScrollPos);
            setVisible(isVisible);
            setMobileMenu(false);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos]);

    const toggleMenu = () => {
        setMobileMenu(!mobileMenu);
    }

    return (
        <ResponsiveWrapper>
            <AnimatedNavbarContainer visible={visible}>
                <Logo src={require('../../assets/Logo/Logo_nobg.png')} />
                <NavbarList>
                    <NavbarItem>
                        <NavbarLink
                            activeClass="active"
                            to="intro"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-70}
                        >
                            Início
                        </NavbarLink>
                    </NavbarItem>
                    <NavbarItem>
                        <NavbarLink
                            activeClass="active"
                            to="psychology"
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            Por que buscar um psicólogo?
                        </NavbarLink>
                    </NavbarItem>
                    <NavbarItem>
                        <NavbarLink
                            activeClass="active"
                            to="about"
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            Sobre mim
                        </NavbarLink>
                    </NavbarItem>
                    <NavbarItem>
                        <NavbarLink
                            activeClass="active"
                            to="appointments"
                            spy={true}
                            smooth={true}
                            duration={500}
                        >
                            Sessões
                        </NavbarLink>
                    </NavbarItem>
                    <NavbarItem>
                        <NavbarLink
                            activeClass="active"
                            to="issurances"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={-40}
                        >
                            Convênios
                        </NavbarLink>
                    </NavbarItem>
                    <NavbarItem>
                        <NavbarLink
                            activeClass="active"
                            to="reviews"
                            spy={true}
                            smooth={true}
                            duration={500}
                            offset={0}
                        >
                            Depoimentos
                        </NavbarLink>
                    </NavbarItem>
                </NavbarList>
                {windowWidth <= 1100 ? <MenuIcon style={{ position: 'absolute', right: '50px' }} onClick={toggleMenu} /> : <></>}
            </AnimatedNavbarContainer>
            {mobileMenu && visible ?
                <Expand>
                    <ResponsiveList>
                        <NavbarItem>
                            <NavbarLink
                                activeClass="active"
                                to="intro"
                                spy={true}
                                smooth={true}
                                duration={500}
                                offset={-70}
                            >
                                Início
                            </NavbarLink>
                        </NavbarItem>
                        <NavbarItem>
                            <NavbarLink
                                activeClass="active"
                                to="psychology"
                                spy={true}
                                smooth={true}
                                duration={500}
                            >
                                Por que buscar um psicólogo?
                            </NavbarLink>
                        </NavbarItem>
                        <NavbarItem>
                            <NavbarLink
                                activeClass="active"
                                to="about"
                                spy={true}
                                smooth={true}
                                duration={500}
                            >
                                Sobre mim
                            </NavbarLink>
                        </NavbarItem>
                        <NavbarItem>
                            <NavbarLink
                                activeClass="active"
                                to="appointments"
                                spy={true}
                                smooth={true}
                                duration={500}
                            >
                                Sessões
                            </NavbarLink>
                        </NavbarItem>
                        <NavbarItem>
                            <NavbarLink
                                activeClass="active"
                                to="issurances"
                                spy={true}
                                smooth={true}
                                duration={500}
                                offset={-40}
                            >
                                Convênios
                            </NavbarLink>
                        </NavbarItem>
                        <NavbarItem>
                            <NavbarLink
                                activeClass="active"
                                to="reviews"
                                spy={true}
                                smooth={true}
                                duration={500}
                                offset={0}
                            >
                                Depoimentos
                            </NavbarLink>
                        </NavbarItem>
                    </ResponsiveList>
                </Expand> : <></>}
        </ResponsiveWrapper>
    );
};

export default Navbar;
