import styled from "styled-components";

interface ButtonProps {
    marginTop?: string;
}

export const Container = styled.div<ButtonProps>`
    display: flex;
    border-radius: 25px;
    cursor: pointer;
    flex-direction: row;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: ${props => props.marginTop ? props.marginTop : 0};
    align-items: center;
    gap: 15px;
    background-color: white;
    filter: drop-shadow(1px 2px 3px black);

    &:hover{
        transform: scale(1.05);
        transition: 0.1s ease-in;
    }

    @media(max-width: 750px){
        margin-bottom: 25px;
        margin-top: 15px;
    }
`;

export const ButtonText = styled.p`
    width: 100%;
    text-align: left;
    font-size: 21px;
    line-height: 23px;
    letter-spacing: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
`;