import AppointmentButton from "../../components/AppointmentButton/Index";
import { Container, ContentBox, ImageTitle, PageText, PageTitle, Picture } from "./Style";


const Intro = () => {
    return (
        <Container>
            <ContentBox>
                <ImageTitle src={require('../../assets/Extra/EduardoName.png')}/>
                <PageText>
                Olá sou psicólogo clínico e meu propósito e te orientar a desenvolver habilidades para superar os desafios do dia a dia, buscando uma vida mais plena e feliz!
                </PageText>
                <PageText><b>CRP 19844/DF</b></PageText>
                <AppointmentButton marginTop="50px"></AppointmentButton>
            </ContentBox>
            <ContentBox>
                <Picture src={require("../../assets/Eduardo/Eduardo_nobg2.png")}/>
            </ContentBox>
        </Container>
    );
};

export default Intro;