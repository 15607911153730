import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Container, Heading, SliderControler, SliderArrow, SwiperPagination, CardContent, Author, CardText } from './Style';
import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';
import { Reviews } from '../../utils/Reviews';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import DataDisplayCard from '../DataDisplayCard/Index';

const Carrousel = () => {

    return (
        <Container>
            <Heading>Depoimentos Reais de Pacientes</Heading>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={'auto'}
                loop={true}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 2.5,
                }}
                pagination={{ el: '.swiper-pagination', clickable: true }}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                style={{
                    height: "525px",
                    padding: "2rem 0",
                    position: "relative",
                }}

            >
                {Reviews.map((review, index) => (
                    <SwiperSlide style={{
                        width: "310px",
                        height: "460px",
                        position: "relative",
                        borderRadius: "15px"
                    }} key={index}>
                        <DataDisplayCard
                            content={
                                <CardContent>
                                    <CardText>{review.text}</CardText>
                                    <Author>{review.author}</Author>
                                </CardContent>
                            }
                        />
                    </SwiperSlide>
                ))}
                <SliderControler>
                    <SliderArrow className="swiper-button-prev">
                        <ArrowCircleLeft style={{ color: 'white', filter: "drop-shadow(1px 2px 3px black)", position: 'absolute' }} />
                    </SliderArrow>
                    <SliderArrow className='swiper-button-next'>
                        <ArrowCircleRight style={{ color: 'white', filter: "drop-shadow(1px 2px 3px black)", position: 'absolute' }} />
                    </SliderArrow>
                    <SwiperPagination className='swiper-pagination'></SwiperPagination>
                </SliderControler>
            </Swiper>
        </Container>
    );
}

export default Carrousel;