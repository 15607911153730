import styled from "styled-components";
import { animated } from 'react-spring';

export const Container = styled.div`
    display: flex;
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: hidden;
    flex-flow: column;
    align-items: center;
    padding: 2.5vw;
`;

export const Section = styled(animated.section)`
    display: flex;
    max-width: 100vw;
`;

export const Splitter = styled.img`
    width: 65%;
    margin-top: 25px;
    margin-bottom: 25px;
`;
