import { ChangeEvent, KeyboardEvent, ClipboardEvent } from "react";
import { Container, Field, FieldTitle } from "./Style";

interface InputFieldInterface {
    setValue: (value: string) => void;
    title: string;
    placeholder?: string;
    type?: string;
    width: string;
    value?: string;
    format?: string;
}

const InputField = ({
    setValue,
    title,
    placeholder,
    type,
    width,
    value,
}: InputFieldInterface) => {

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        let input = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
        if (input.length > 11) {
            input = input.slice(0, 11); // Limita a entrada a 11 dígitos
        }
        setValue(input); // Atualiza o estado com a entrada sanitizada
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (!/[0-9]/.test(e.key) && 
            e.key !== 'Backspace' && 
            e.key !== 'Delete' && 
            e.key !== 'ArrowLeft' && 
            e.key !== 'ArrowRight' && 
            e.key !== 'Tab') {
            e.preventDefault(); // Previne a entrada de caracteres não numéricos
        }
    };

    const handlePaste = (e: ClipboardEvent<HTMLInputElement>): void => {
        const paste = e.clipboardData.getData('text');
        if (!/^[0-9]*$/.test(paste)) {
            e.preventDefault(); // Previne a colagem de caracteres não numéricos
        }
    };

    const formatPhoneNumber = (number: string): string => {
        if (number.length === 11) {
            return number.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        } else if (number.length === 9) {
            return number.replace(/(\d{5})(\d{4})/, '$1-$2');
        }
        return number;
    };

    return (
        <Container width={width}>
            <FieldTitle>
                {title}
            </FieldTitle>
            <Field
                type={type || 'text'}
                value={type === 'tel' ? formatPhoneNumber(value || '') : value}
                onChange={type === 'tel' ? handleInputChange : (e) => setValue(e.target.value)}
                onKeyDown={type === 'tel' ? handleKeyDown : undefined}
                onPaste={type === 'tel' ? handlePaste : undefined}
                placeholder={placeholder}
            />
        </Container>
    );
};

export default InputField;
