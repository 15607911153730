import { Facebook, Instagram, Link, WhatsApp } from "@mui/icons-material";
import { Container, Icon } from "./Style";

const ReachMe = () => {
    return (
        <Container>
            <Icon onClick={() => { window.open('https://www.instagram.com/psicologo.eduardocoelho/') }}><Instagram style={{ color: '#d816c8', width: '35px', height: '35px' }} /></Icon>
            <Icon onClick={() => { window.open('https://www.facebook.com/psicologoeduardocoelho') }}><Facebook style={{ color: '#1F57A1', width: '35px', height: '35px' }} /></Icon>
            <Icon onClick={() => { window.open('https://api.whatsapp.com/send?phone=5561996229597&text=Seja%2C%20bem%20vindo%20a%20Clinica%20de%20Psicologia%20do%20Dr%C2%BA%20Eduardo%20Coelho.%20Para%20agilizar%20o%20atendimento%2C%20pode%20informar%20o%20seu%20nome%20e%20como%20podemos%20te%20ajudar%20neste%20momento%3F') }}><WhatsApp style={{ color: 'green', width: '35px', height: '35px' }} /></Icon>
            <Icon onClick={() => { window.open('https://linkme.bio/psicologo.eduardocoelho') }}><Link style={{ color: 'grey', width: '35px', height: '35px' }} /></Icon>
        </Container>
    )
}

export default ReachMe