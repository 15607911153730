import { useState, useEffect } from "react";
import IssuranceCard from "../../components/IssuranceCard/Index";
import { Issurance, Issurances } from "../../utils/Issurances";
import { CardsContainer, Card, Container, PanelTitle, PanelText, ShowMoreButton, ButtonText, CardButtonWrapper, ModalCardsWrapper, ModalBody } from "./Style";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BlankModal from "../../components/BlankModal/Index";
import SearchBar from "../../components/SearchBar/Index";

const IssurancesPanel = () => {
    const [cards, setCards] = useState<JSX.Element[]>([]);
    const [totalCards, setTotalCards] = useState<number>(10);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const [modalStatus, setModalStatus] = useState<boolean>(false);
    const [filteredCards, setFilteredCards] = useState<Issurance[]>([]);
    const [filter, setFilter] = useState<string>();

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowWidth <= 750) {
            setTotalCards(6);
        }
        else if (windowWidth <= 1175) {
            setTotalCards(9);
        } else if (windowWidth <= 1450) {
            setTotalCards(8);
        } else {
            setTotalCards(10);
        }
    }, [windowWidth]);

    useEffect(() => {
        const generateRandomCards = () => {
            const randomIndexes: number[] = [];
            while (randomIndexes.length < totalCards) {
                const index = Math.floor(Math.random() * Issurances.length);
                if (!randomIndexes.includes(index)) {
                    randomIndexes.push(index);
                }
            }
            const randomCards = randomIndexes.map(index => {
                const issurance = Issurances[index];
                return <Card key={index}><IssuranceCard name={issurance.name} logo={issurance.logo} /></Card>;
            });
            setCards(randomCards);
        };

        generateRandomCards();

        const intervalId = setInterval(() => {
            generateRandomCards();
        }, 10000);

        return () => clearInterval(intervalId);
    }, [totalCards]);


    const toggleModal = () => {
        setModalStatus(!modalStatus);
    }

    const renderModalCards = () => {
        if (filter) {
            if (filteredCards.length === 0) {
                return (<h1>Nenhum convênio encontrado.</h1>)
            }
            return filteredCards.map(card => {
                return <IssuranceCard name={card.name} logo={card.logo} />
            })
        }
        return Issurances.map(issurance => {
            return <IssuranceCard name={issurance.name} logo={issurance.logo} />
        })
    }

    useEffect(() => {
        if (filter) {
            setFilteredCards(Issurances.filter(issurance => issurance.name.toLowerCase().includes(filter.toLocaleLowerCase())));
        } else {
            setFilteredCards([]);
        }
    }, [filter])

    return (
        <Container>
            <PanelTitle>
                Convênios
            </PanelTitle>
            <PanelText>
                Além de sessões particulares, para garantir a melhor condição possível para o nosso encontro, também aceitamos os mais variados planos de saúde. Confira se o seu plano é aceito em nossa clinica e agende sua sessão.
            </PanelText>
            <CardButtonWrapper>
                <ShowMoreButton>
                    <ButtonText onClick={toggleModal}>
                        Ver Planos
                    </ButtonText>
                    <AddCircleOutlineIcon></AddCircleOutlineIcon>
                </ShowMoreButton>
                <CardsContainer>
                    {cards}
                </CardsContainer>
            </CardButtonWrapper>
            <BlankModal headerText="Convênios" isShown={modalStatus} hide={toggleModal} bodyContent={
                <ModalBody>
                    <SearchBar setSearchedWord={setFilter}></SearchBar>
                    <ModalCardsWrapper>{renderModalCards()}</ModalCardsWrapper>
                </ModalBody>
            } />
        </Container>
    );
};

export default IssurancesPanel;
