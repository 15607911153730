import { Issurance } from "../../utils/Issurances";
import { Container, Logo, Name } from "./Style";

interface IssuranceCardInterface {
    name: string;
    logo: string;
} 

const IssuranceCard = ({name, logo}: IssuranceCardInterface) => {
    return (
        <Container onClick={() => {console.log(logo)}}>
            <Logo src={logo} alt={name}/>
            <Name>
                {name}
            </Name>
        </Container>
    )
}

export default IssuranceCard;