import { Container, Copy,WPP, Logo } from "./Style";
import ReachMe from "../../components/ReachMe/Index";

const Footer = () => {
    return (
        <Container>
            <Logo src={require('../../assets/Logo/Logo_nobg.png')}></Logo>
            <ReachMe />
            <Copy>© 2024 | Todos os direitos reservados | Eduardo Coelho | Desenvolvido por <WPP href="https://api.whatsapp.com/send?phone=5561981518798&text=Olá,%20vi%20um%20de%20trabalhos%20e%20me%20interessei">Thiago França</WPP></Copy>
        </Container>
    );
};

export default Footer;