import styled from "styled-components";

interface RadioInputProps {
    width: string
}

export const Container = styled.div<RadioInputProps>`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: ${(props) => props.width};
`;

export const FieldTitle = styled.p`
    font-size: 20px;
    margin-bottom: 10px;
`;

export const Field = styled.input`
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    outline: none;
    margin-left: 0px;
    margin-top: 0px;
    cursor: pointer;

    /* Cria uma aparência personalizada para o botão de rádio */
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid black;
    background-color: white;

    /* Estilo quando o botão de rádio está marcado */
    &:checked {
        background-color: #1F57A1; /* Altere a cor conforme desejado */
        border-color: #1F57A1; /* Altere a cor conforme desejado */
    }
`;

export const FieldLabel = styled.label`
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 0px;
    cursor: pointer;
`;

export const FieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    align-items: center;
    cursor: pointer;
    gap: 5px;
`;
