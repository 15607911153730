import styled from "styled-components";

interface ButtonProps {
    primary: boolean;
}

export const Container = styled.div`
    display: flex;
    background-color: white;
    filter: drop-shadow(1px 2px 3px black);
    border-radius: 15px;
    min-width: 550px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 25px;
    box-sizing: border-box;
    @media(max-width: 928px){
        width: 95vw;
        min-width: 350px;
    }
`;

export const MultiField = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 85%;
    gap:8%;
`;

export const FormButton = styled.button<ButtonProps>`
    background-color: ${(props) => props.primary ? '#1F57A1' : 'white'};
    border-radius: 15px;
    width: 100px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 50px;
    height: 35px;
    border: ${(props) => props.primary ? '2px solid #1F57A1' : '2px solid black'};
    color: ${(props) => props.primary ? 'white' : 'black'};
`;

export const FormTitle = styled.h1`
    font-size: 32px;
    margin-bottom: 5px;
    margin-top: 0px;
    font-weight: normal;
    font-family: 'Times New Roman', Times, serif;
    justify-self: center;
    align-self: center;
    width: 95%;
    text-align: center;
`

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    width: 85%;
    justify-content: flex-end;
    flex-direction: row;
`