import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
`;

export const Title = styled.h1`
    width: 100%;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 50px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 0px;
    margin-bottom: 50px;

    @media (max-width: 750px){
        line-height: 44px;
        font-size: 48px;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
    flex-wrap: wrap;
    @media(max-width: 928px){
        gap: 15px;
    }
`;

export const TopicsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    max-width: 600px;
    width: 45%;
    max-height: 700px;
    overflow: hidden;
    padding: 25px;
    padding-bottom:15px;
    margin-left: 50px;
    border-radius: 15px;
    background-color: white;
    filter: drop-shadow(1px 2px 3px black);
    box-sizing: border-box; 
    
    @media(max-width: 928px){
        align-self: center;
        margin-left: 0;
        width: 90%;
        max-width: 700px;
    }
`;

export const PageText = styled.p`
    max-width: 80%;
    text-align: justify;
    font-family: 'Times New Roman', Times, serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    margin-top: 25px;
    margin-bottom: 5px;
    font-style: italic;
    
    @media(max-width: 750px){
        text-align: center;
        max-width: 85%;
    }
`;

export const TextButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
    min-width: 400px;
    height: 100;
    gap: 50px;
    @media(max-width: 928px){
        gap: 15px;
    }
`;

export const TopicsTitle = styled.p`
    width: 120%;
    text-align: center;
    font-size: 26px;
    font-family: 'Times New Roman', Times, serif;
    background-color: aliceblue;
    margin-top: -25px;
    margin-bottom: -1px;
    line-height: 64px;
    z-index: 1;

    @media(max-width: 750px){
        font-size: 22px;
        letter-spacing: 0px;
        margin-bottom: -2px;
    }
`
