import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '90%',
};

const center = {
    lat: -15.840851932553264,
    lng: -48.04411731853281
};

function MiniMap() {

    const mapkey = process.env.REACT_APP_MAPS_KEY;

    console.log(mapkey);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: mapkey
    });

    const handleMarkerClick = () => {
        window.open(`https://www.google.com/maps/search/?api=1&query=${center.lat},${center.lng}`, "_blank");
    };

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
        >
            <Marker
                position={center}
                onClick={handleMarkerClick} // Adiciona o evento de clique ao marcador
            />
        </GoogleMap>
    ) : <></>;
}

export default React.memo(MiniMap);
