import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Container, ContentContainer, Picture, Title, PageText, TextContainer, Subtitles, SubtitlesContainer, Divider } from "./Style";
import { useState } from "react";
import ReachMe from "../../components/ReachMe/Index";

const AboutMe = () => {

    const message: String = "";
    const [about, setAbout] = useState<boolean>(true);

    const information = (open: boolean, title: string) => {
        return (open ? <SubtitlesContainer>
            <Subtitles>
                {title}
            </Subtitles>
            <KeyboardArrowDown></KeyboardArrowDown>
        </SubtitlesContainer> : <SubtitlesContainer>
            <Subtitles>
                {title}
            </Subtitles>
            <KeyboardArrowRight></KeyboardArrowRight>
        </SubtitlesContainer>)
    }

    const toggleAbout = () => {
        setAbout(!about);
    }

    return (
        <Container>
            <Title>
                Sobre Mim
            </Title>
            <ContentContainer>
                <Picture>
                    <ReachMe></ReachMe>
                </Picture>
                <TextContainer>
                    {information(about, "Quem sou eu?")}
                    <PageText visible={about}>
                        &emsp;Me chamo Eduardo Coelho, sou psicólogo formado pelo Centro Universitário IESB de Brasília, me baseio na ciência da Gestalt Terapia para auxiliar meus pacientes em seus processos de autoconhecimento e superação de traumas. Sou psicólogo com experiência em atendimento clínico há mais de 7 anos, e me tornei psicólogo por acreditar que nunca é tarde para mudarmos e ressignificar o que nos adoece.
                    </PageText>
                    <Divider />
                    {information(about, "Minhas especialidades")}
                    <PageText visible={about}>
                        &emsp;Realizo psicoterapia individual, familiar e de casal. Além do serviço de apoio emocional, realizo avaliação psicológica para diagnóstico de TDAH, Vasectomia e Cirurgia Bariátrica.
                    </PageText>
                </TextContainer>

            </ContentContainer>
        </Container>
    );
}

export default AboutMe;