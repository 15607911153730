import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    filter: drop-shadow(1px 2px 3px black);
    width: 180px;
    height: 100px;
    padding: 10px;

    @media(max-width: 750px){
        width: 144px;
        height: 80px;
        padding: 8px;
    }
`;

export const Logo = styled.img`
    max-width: 170px;
    height: 75px;

    @media(max-width: 750px){
        max-width: 136px;
        height: 60px;
    }
`;

export const Name = styled.p`
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 5px;
    margin-bottom: 0px;
    color: gray;
    text-overflow: ellipsis;
    text-align: center;
    
    @media(max-width: 750px){
        font-size: 16px;
        margin-top: 4px;
    }
`;