import styled from "styled-components";

export const Topic = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px;
    width: 100%;
    height: 45px;
    align-items: center;
    flex-direction: row;
    border-top-style: solid;
    border-width: 1px;
    cursor: pointer;
`;

export const TopicText = styled.p`
    max-width: 94%;
    font-family: 'Times New Roman', Times, serif;
    font-size: 19px;
    text-align: start;
    line-height: 20px;
    @media(max-width: 750px){
        font-size: 18px
    }
`;