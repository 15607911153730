import styled from 'styled-components';

export const Container = styled.div`
  max-width: 95%;
  margin: 0 auto;
`;

export const Heading = styled.h1`
    width: 100%;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    font-size: 50px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 0px;
    margin-bottom: 20px;

    @media (max-width: 750px){
      line-height: 44px;
      font-size: 48px;
    }
`;

export const SwiperSlide = styled.div`
  width: 37rem;
  height: 42rem;
  position: relative;

  @media (max-width: 500px) {
    width: 28rem !important;
    height: 36rem !important;
  }
`;

export const SliderControler = styled.div`
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  max-width: 30%;
`;

export const SliderArrow = styled.div`
  color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
`;

export const SwiperPagination = styled.div`
  display: flex;
  justify-content: center;

  .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }

  .swiper-pagination-bullet-active {
    background-color: white;
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1)) !important;
  }
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`

export const CardText = styled.p`
    margin-top: 10px;
    width: 100%;
    text-align: justify;
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
    font-weight: 500px;
    line-height: 20px;
    letter-spacing: 0px;
    text-overflow: ellipsis;
    max-height: 80%;
`

export const Author = styled.p`
    width: 100%;
    text-align: left;
    font-family: 'Times New Roman', Times, serif;
    font-size: 28px;
    font-weight: 100px;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: end;
    bottom: 0px;
    position: absolute;
    margin-right: 50px;
    background-color: aliceblue;
    padding: 5px;
    border-radius: 100px 100px 100px;
    padding-right: 10px;
`