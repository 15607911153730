import styled from "styled-components";

export const Container = styled.div`

    display: flex;
    width: 80%;
    padding: 1%;
    padding-left: 2%;
    padding-right: 2%;
    background-color: white;
    border-radius: 30px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 15px;
`;

export const SearchInput = styled.input`

    background-color: white;
    border: none;
    width: 90%;
    font-size: 20px;
    outline: none;
`;